.root {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.selectors {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 0.5rem;
}

.selector {
  min-width: 15rem;
  :global {
    .ant-select-selection-overflow {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 0.5rem;
    }
    .ant-select-selector {
      padding-left: 0 !important;
      padding-inline-end: 1.5rem !important;
    }
  }
}

.addGroup {
  margin-left: auto;
  margin-top: 0.25rem;
}

.selectorWrapper {
  display: flex;
  align-items: center;
  border-radius: 0.5rem;
  border: 1px solid var(--neutral5);
  padding: 0.15rem 0.3rem;
}

.labelConnector {
  padding: 0 !important;
}

.individualsWrapper {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.addIndividuals {
  padding: 0;
  color: var(--primary-main6);
  font-weight: 500;
  font-size: 0.75rem;
}

.dropdownOptions {
  min-width: 400px;
}

.recipientsLabelWrapper {
  margin-top: 0.25rem;
  display: flex;
  flex-direction: column;
}

.selectorLabel {
  color: var(--neutral6);
  font-size: 0.938rem !important;
  font-weight: 700;
}

.tagOptionWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0 !important;
}

.tagOption {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.5rem;
  font-weight: 500;
}

.tagLabel {
  font-weight: 400 !important;
  color: var(--neutral6);
  padding: 0 !important;
  &::after {
    content: ':';
  }
}

.closeTagIcon {
  cursor: pointer;
  color: var(--neutral6);
}

.removeButton {
  padding: 0.25rem;
}

.totalRecipients {
  color: var(--neutral6);
  font-size: 0.75rem !important;
  font-weight: 500;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-skip-ink: auto;
  text-decoration-thickness: auto;
  text-underline-offset: auto;
  text-underline-position: from-font;
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  text-align: left;
  font-family: 'Inter', sans-serif;
}

.marginRight {
  margin-right: 0.5rem;
}

.modalTitle {
  color: var(--blue-dark);
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}

.modalSearch {
  max-width: 18rem;
  padding: 1rem;
}
