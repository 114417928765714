@import 'src/styles/basics';

.root {
  padding: $page-padding;
}

.title {
  padding: 0.5rem 0 0 0;
}

.title,
.content {
  font-size: 1rem !important;
  line-height: 150% !important;
}

.title {
  font-weight: 600;
}

.content {
  padding-left: 0;
}

.footerDivider {
  opacity: 0.2;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  gap: 1rem;
}

.buttonWrapper {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}

.header {
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  h2 {
    flex: 1;
    font-weight: $headerFontWeight;
    font-size: 24px;
    line-height: 28px;
    color: var(--primary-main8);
    margin-bottom: 12px;
  }
  h3 {
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
  }
}

.inputWrapper {
  margin: 0;
}

.workspaceSelector {
  margin-left: auto;
  color: var(--primary-main6);
  font-weight: 700;
  align-self: center;
  cursor: pointer;
}

.multiInput {
  background-color: $white;
  border: 1px solid $grayLight1;
  border-radius: $input-radius;
  padding: 1rem 1.5rem 1.5rem 1.5rem;
}

.toggles {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
}

.inputUrl {
  border: 1px solid $grayLight1 !important;
  border-radius: $input-radius;
  height: 40px !important;
  margin-top: 12px !important;
}

.resourceItem {
  background-color: $veryLightBlue;
  border-radius: 5px;
  border-bottom: none;
  margin-bottom: 0.5rem;

  &:hover {
    background-color: $veryLightBlue;
    opacity: 0.6;
  }
}

.dismissButton {
  margin: 0 auto;
  max-width: 300px;
  width: 100%;
}

.doctorWrapper {
  flex: 1;
  text-align: center;
  margin-bottom: 20px;
}

.doctor {
  width: 170px;
}

.describer {
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  margin: 10px 0;
  color: $grayLight;
}

.urlMessage {
  margin: 10px 0 0 10px;
}

.toggleWrapper {
  align-items: center;
  display: flex;
  gap: 0.5rem;
}

.sendIcon {
  margin-right: 0.5rem;
}

.checkLabel {
  line-height: 18px;
  color: rgba(113, 113, 113, 1);
}

.error {
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
}

.errorLabel {
  color: #d6473e !important;
}

.errorIcon {
  width: 20px;
  height: 20px;
  color: #d6473e;
  margin-right: 0.2rem;
}
