@import 'src/styles/basics';

.root {
  display: flex;
  min-height: 100vh;
  width: 100vw;
  background: var(--primary-main1);
  display: flex;
  flex-direction: column;
}

.main {
  flex: 1;
  display: flex;
  flex-direction: row;
  max-height: 100%;
  position: relative;
  padding-top: var(--header-height);
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: 4rem;
}
