@import 'src/styles/basics';

.root {
  display: flex;
  flex-direction: row;
  padding: 0.75rem 0;
  justify-content: space-between;
  border-bottom: 1px solid var(--neutral3);
}

.rightSide {
  gap: var(--margin-2);
  display: flex;
  flex-direction: row;
}

.body {
  display: flex;
  flex-direction: column;
  gap: 0.125rem;
}

.attachmentsCount {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  label {
    padding: 0 !important;
  }
}

.title {
  color: var(--primary-main9) !important;
  font-size: var(--font-size-medium) !important;
  font-weight: 400 !important;
  line-height: 120% !important;
  letter-spacing: -0.25px;
}

.date {
  font-size: 0.75rem;
  font-weight: 500;
  color: var(--neutral6);
}
