.root {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: var(--margin-5) var(--margin-3) var(--margin-3);
  overflow-y: auto;
  position: relative;
}

.message {
  display: flex;
  margin-bottom: var(--margin-3);
}

.answerWrapper {
  display: flex;
  flex-direction: column;
}

.optimizedQuestion {
  margin-bottom: 0.5rem;
  margin-top: -0.75rem;

  span {
    font-size: 0.75rem !important;
    color: var(--neutral7);
  }
  p {
    font-size: 0.875rem !important;
    font-style: italic;
  }
}

.avatarAndContentWrapper {
  display: flex;
}

.avatarWrapper {
  margin-right: var(--margin-2);
}

.avatar {
  background: #d9d9d9;
  border: 2px solid #c2c2c2;
  height: 3.75rem;
  width: 3.75rem;
  flex-shrink: 0;
  margin-right: var(--margin-2);
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  .panda {
    height: 100%;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    object-fit: contain;
    object-position: bottom;
  }

  .avatarName {
    color: var(--neutral6);
    font-weight: 600;
    font-size: 1.35rem;
  }
}

.content {
  flex: 1;

  p {
    white-space: pre-wrap;
  }

  .details {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
  }

  .ownerWrapper {
    display: flex;
    align-items: center;

    &:hover {
      cursor: pointer;
    }
  }

  .ownerSection {
    display: inline-block;
    .ownerFullName {
      color: var(--primary-main6);
      text-transform: capitalize;
    }
  }

  .referencesPanel {
    display: flex;
    flex-direction: column;
    gap: 1.375rem;
  }

  .resourcesPanel {
    span,
    h4 {
      font-family: 'Raleway', sans-serif;
      font-size: 14px;
      font-weight: 500;
    }

    h4 {
      color: #193f6a;
    }
    span {
      color: rgba(25, 63, 106, 0.6);
    }
  }

  .collapseHeader {
    color: var(--primary-main6);
    font-weight: 300;
  }

  .bottomActions {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 1rem;
    gap: 0.5rem;
    font-weight: 400 !important;

    button {
      background: transparent;
      border: 0;
      padding: 0;
      margin: 0;

      &.disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }

    p {
      color: var(--primary-main6);
      font-size: 0.75rem !important;
      letter-spacing: -0.3px;
      font-style: normal;
    }

    svg {
      color: var(--primary-main6);
      height: 0.8rem;
      width: 0.8rem;
    }

    span {
      color: var(--neutral5);
      font-size: 0.75rem !important;
    }

    @mixin responseAction {
      display: flex;
      align-items: center;
      cursor: pointer;
      gap: 0.3rem;

      &:hover {
        opacity: 0.5;
      }
    }

    .copyResponse {
      @include responseAction;
    }

    .badResponse {
      @include responseAction;
    }

    .goodResponse {
      @include responseAction;
    }

    .regenerateResponse {
      @include responseAction;
    }
  }

  .question {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 0.5rem;

    .sendCancelButtons {
      display: flex;
      gap: 0.5rem;
      justify-content: flex-end;
    }

    .editButton {
      background: transparent;
      border: 0;
      padding: 0;
      margin: 0;
      transition: all 0.2s ease-in-out;
      color: var(--primary-main6);

      &:hover {
        opacity: 0.5;
      }

      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }
  .botNameTitle {
    display: flex;
    gap: 0.2rem;
  }
}

.history {
  display: flex;
  align-items: center;
  gap: 0.4rem;

  > button {
    background: transparent;
    border: 0;
    padding: 0;
    margin: 0;

    transition: all 0.2s ease-in-out;
    color: var(--primary-main6);

    &:hover {
      opacity: 0.7;
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  > p {
    font-size: 0.75rem;
    letter-spacing: -1%;
    line-height: 120%;
  }
}

.historyMessageWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

@keyframes scale-up-ver-top {
  0% {
    transform: scaleY(0.4);
    transform-origin: 100% 0%;
  }
  100% {
    transform: scaleY(1);
    transform-origin: 100% 0%;
  }
}

@keyframes scale-down-ver-top {
  0% {
    transform: scaleY(1);
    transform-origin: 100% 0%;
  }
  100% {
    transform: scaleY(0.3);
    transform-origin: 100% 0%;
  }
}

.badFeedbackPanel {
  border: 1px solid var(--neutral5);
  padding: 1.5rem;
  margin: 1rem 0;
  border-radius: 9px;
  &.open {
    animation: scale-up-ver-top 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  }
  &.close {
    animation: scale-down-ver-top 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }

  .badFeedbackTitle {
    font-size: 0.75rem;
    font-family: 'Raleway', sans-serif;
    font-weight: 600;
    color: var(--neutral6);
    margin-bottom: 0.5rem;
  }

  .badFeedbackOptions {
    display: flex;
    flex-wrap: wrap;
    gap: 0.75rem;
    margin: 1rem 0;

    button {
      border: none;
      background-color: val(--neutral3);
      border-radius: 32px;
      padding: 0.5rem 1rem;
      text-align: center;
      font-size: 0.75rem;

      &:hover {
        opacity: 0.5;
      }

      &.selected {
        background-color: var(--neutral4);
      }
    }
  }

  .feedbackFormTextArea {
    font-size: 0.75rem;
    &::placeholder {
      font-size: 0.75rem;
    }
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
  }
}

.thanksMessage {
  display: flex;
  align-items: center;
  border: 1px solid var(--neutral5);
  padding: 0.75rem;
  border-radius: 9px;
  margin: 1rem 0;
  gap: 0.875rem;
  background-color: var(--neutral2);

  p,
  svg {
    color: var(--neutral6);
  }

  p {
    font-size: 0.875rem;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
  }
}

.loader {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: fit-content;
  margin-top: 0.5rem;

  .dot {
    width: 0.35rem;
    height: 0.35rem;
    margin: 0.15rem;
    background-color: #f37321;
    border-radius: 50%;
    animation: scaleDot 1s infinite;

    &:nth-child(1) {
      animation-delay: 0s;
    }

    &:nth-child(2) {
      animation-delay: 0.1s;
    }
    &:nth-child(3) {
      animation-delay: 0.2s;
    }
    &:nth-child(4) {
      animation-delay: 0.3s;
    }
    &:nth-child(5) {
      animation-delay: 0.4s;
    }
  }
}

@keyframes scaleDot {
  50% {
    transform: translateY(0);
  }
  80% {
    transform: translateY(-0.3rem);
  }
}

.reference {
  border-left: 3px solid #f37321;
  padding-left: var(--margin-1);
  margin-top: var(--margin-2);
  position: relative;
  font-size: var(--font-size-label);
  font-weight: 400;

  &:first {
    margin-top: var(--margin-2);
  }

  .expandedBtn {
    background: transparent;
    color: #f37321;
    border: 0;
    padding: 0;
    transition: all 0.2s ease-in-out;
    font-size: var(--font-size-label);
    font-weight: 700;
    letter-spacing: -0.13px;
    text-decoration-line: underline;
    font-family: 'Inter', sans-serif;
    padding-left: 0.8rem;

    &:hover {
      opacity: 0.5;
    }
  }

  .referenceContent {
    overflow: hidden;
    max-height: 2.5rem;
    max-width: 100%;
    transition: max-height 0.5s ease-out;

    padding: 0px 0.625rem 0px 0.8rem;

    &.expanded {
      max-height: 60vh;
      overflow-y: auto;
    }
  }

  h1,
  h2,
  h3 {
    font-size: 1rem !important;
    font-weight: 600 !important;
    line-height: 1.2 !important;
  }
  img {
    height: auto;
    width: 100%;
    object-fit: contain;
    margin: 0.5rem 0;
    border-radius: 0.375rem;
  }
  p,
  li {
    font-weight: 400 !important;
    letter-spacing: -0.13px !important;
    font-family: 'Inter', sans-serif;
  }

  ol,
  ul {
    list-style-type: revert;
    padding-inline-start: 1rem;
  }
}

.clearChat {
  position: absolute;
  right: var(--margin-2);
  top: var(--margin-2);
  cursor: pointer;
  background: transparent;
  border: 0;
  padding: 0;
  margin: 0;
  transition: all 0.2s ease-in-out;
  color: var(--primary-main6);
  font-weight: 700;
  text-transform: uppercase;
  font-family: 'Inter', sans-serif;

  &:hover {
    opacity: 0.5;
  }
}

.messageContent {
  white-space: normal !important;
  padding-top: var(--margin-1);

  ol,
  ul {
    list-style-type: revert;
    padding-inline-start: 1rem;
  }

  p,
  span,
  li {
    margin-block-end: 0.5rem;
  }
}
