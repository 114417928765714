@import 'src/styles/basics';

.root {
  display: flex;
  flex-direction: column;
  margin: var(--margin-4);
}

.card {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border-radius: 0.5rem;
  border: 1px solid var(--neutral4);
  background-color: var(--white);
}

.header {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.headerTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.userAndDate {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  h3 {
    color: var(--neutral6);
  }
}

.resourceItem {
  background-color: $veryLightBlue;
  border-radius: 5px;
  border-bottom: none;
  margin-bottom: 0.5rem;

  &:hover {
    background-color: $veryLightBlue;
    opacity: 0.6;
  }
}
.attachments {
  margin-top: 2rem;
}

.linkToUser {
  &:hover {
    text-decoration: underline !important;
  }
}

.actionIcon {
  cursor: pointer;
  border-radius: 0.5rem;
  padding: 0.875rem;
  border-color: var(--primary-main6);
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.5;
  }
}

.goBack {
  max-width: fit-content;
}

.content {
  // Markdown styles
  p {
    font-size: 1rem !important;
    line-height: 150% !important;
  }
  ul {
    list-style-type: revert;
    padding-inline-start: 1rem;
  }

  p,
  span,
  li {
    margin-block-end: 0.1rem;
  }
}

.actionsBarRight {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.replyButton {
  padding: 0.55rem 0.875rem 0.527rem 0.875rem !important;
  height: auto !important;
}

.moreRecipients {
  padding: 0 !important;
  p {
    color: var(--primary-main6) !important;
    text-decoration: underline;
  }
}

.recipientsLabel {
  color: var(--neutral6) !important;
  font-size: 0.938rem !important;
  font-weight: 400 !important;
  letter-spacing: -0.15px !important;
}

.recipientName {
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}
