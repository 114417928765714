@import 'src/styles/basics';

.content {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  max-height: 27.5rem;
}

.widgetItems {
  display: flex;
  border-radius: 10px;
  background: white;
  flex-direction: column;
  transition: all 200ms ease-in-out;
  display: flex;
  height: 100%;
  overflow-y: scroll;
  padding: 1rem 1rem 0 1rem;

  &.inActive {
    opacity: 0.5 !important;
  }
}

.title {
  color: var(--neutral7) !important;
  font-weight: 600 !important;
  font-size: 1.125rem !important;
  margin: 1rem 0;
}

.sectionTitle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  .email {
    font-size: 0.8rem;
    font-weight: 700;
    color: var(--primary-main6);

    &:hover {
      opacity: 0.5;
    }
  }

  .emailSent {
    color: var(--neutral700);
    opacity: 0.5;
  }
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-height: 20px;
  align-items: center;

  .progress {
    width: 40%;
    display: flex;
    flex-direction: column;
    gap: var(--margin-03);

    .progressBar {
      height: 5px;
      width: 100%;
    }

    p {
      margin-top: var(--margin-1);
      align-self: flex-end;
    }
  }
}

.noContent {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;
  padding-bottom: 1rem;

  img {
    width: 145px;
    height: 134px;
  }

  p {
    font-size: var(--font-size-h3);
    font-weight: 500;
    margin-top: var(--margin-2);
  }
}

.titleDivider {
  margin: var(--margin-2) 0;
  background-color: var(--neutral5);
}

.loadingIcon {
  align-self: center;
}
