.root {
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

.fields {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 50%;
}

.sectionTitle {
  color: var(--primary-main9);
  font-size: 1rem;
  font-weight: 700;
}

.sectionHeader {
  display: flex;
  align-items: center;
  gap: 0.625rem;
}

.selector {
  min-width: 15rem;
  :global {
    .ant-select-selection-overflow {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 0.5rem;
    }
    .ant-select-selector {
      padding-left: 0 !important;
      padding-inline-end: 1.5rem !important;
    }
  }
}

.scheduleUrlsList {
  padding: 1rem 0 0.5rem 0;
}

.quickLinksList {
  padding: 1rem 0;
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 1rem 4rem;
  flex-wrap: wrap;
}

.modalTitle {
  color: var(--blue-dark);
}

.label {
  font-size: 0.75rem;
  color: var(--neutral6);
  padding: 0 !important;
}

.dashboardBannerContent {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.imageWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
}

.imageLink {
  font-weight: 700;
  color: var(--primary-main6);
}

.previewText {
  color: var(--primary-main9);
  font-size: 1rem;
  font-weight: 700;
}

.calendarLabel {
  display: flex;
  gap: 0.5rem;
}

.managers {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.managersLabel {
  font-size: 12px;
  font-weight: 600;
  color: var(--neutral6);
}

.managersPreview {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.disabledOwners {
  color: var(--primary-main9);
  opacity: 0.6;
}

.submitButtons {
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
  padding-bottom: 1rem;
}
