@import 'src/styles/basics';

.dashboardWrapper {
  margin: var(--margin-4);
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 1rem;

  &.light {
    background: $knowledgeItemBackgroundLight;
  }
}

.newsFeed {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-right: 20px;
  min-width: 400px;
  gap: 0.5rem;
}

.widgets {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-right: 40px;
  min-width: 350px;
  gap: 1rem;
}

.notFound {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;

  h4 {
    color: $searchEmptyScreenHeader;
    font-size: 22px;
    font-weight: 300;
    margin-bottom: 30px;
  }
}

.title {
  color: var(--primary-main8) !important;
  margin: 30px 0 15px;
  font-weight: 300 !important;
  font-size: 20px !important;
  background: transparent !important;
  border-color: transparent !important;
}

.banner {
  outline: none;

  img {
    width: 100%;
    height: 11.25rem;
    object-fit: cover;
  }
}
