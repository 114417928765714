@import 'src/styles/basics';

.root {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 30vh;

  h4 {
    font-size: 1.1rem !important;
    font-weight: 300;
    margin-top: 1rem;
    margin-bottom: 0;
  }
}

.panda {
  height: 150px;
  width: 150px;
  object-fit: contain;
  object-position: center;
}

.withMixBlendMode {
  mix-blend-mode: luminosity;
}
