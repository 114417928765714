@import 'src/styles/basics';

.searchType {
  display: flex;
  justify-content: space-between;
  margin: 20px 0 5px;
  color: $filterByTypeText;

  button {
    background: transparent;
    border: 0;
    font-family: 'Inter', sans-serif;

    &.active {
      font-weight: $headerFontWeight;
      color: $filterByTypeTextActive;
    }
  }
}
