.actionsIcon {
  color: var(--primary-main6);
  height: 1.2rem;
  width: 0.8rem;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
}

.dropdownMenu {
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  background: var(--neutral1);
  border-radius: 0.5rem;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
  width: 9.8rem;
  z-index: 1;
  overflow: hidden;
}

.item {
  display: flex;
  height: 2.5rem;
  padding: 0.62rem 1rem;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-size: 0.94rem;
  font-weight: 400;
  line-height: 1.125rem;
  letter-spacing: -0.01rem;
  border-radius: 0;
  color: var(--primary-main7);

  &:hover {
    background: var(--primary-main1);
  }

  &:disabled {
    color: var(--gray-dark);
    cursor: not-allowed;
  }
}
