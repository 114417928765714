@import 'src/styles/basics';

.title {
  color: var(--neutral7) !important;
  font-weight: 600 !important;
  font-size: 1.125rem !important;
}

.items {
  display: flex;
  flex-direction: column;
  max-height: 27.5rem;
  overflow: auto;
}

.divider {
  margin: 0;
}
