@import 'src/styles/basics';

.calendar {
  padding: 15px;
  background-color: $white;
  border-radius: 10px;
  transition: all 200ms ease-in-out;

  iframe {
    width: 100%;
    height: 425px;
  }
  &.inActive {
    opacity: 0.5 !important;
  }
}

.title {
  color: var(--neutral7) !important;
  font-weight: 600 !important;
  font-size: 1.125rem !important;
  margin-top: 1rem;
}

.listMap {
  padding: 2rem;
  background-color: $white;
  border-radius: 10px;
  transition: all 200ms ease-in-out;
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem 0.8rem;
}

.calendarItem {
  flex: 0 1 22%;
  max-width: 22%;
  display: flex;
  justify-content: center;

  a {
    flex-wrap: wrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--neutral8);
    display: flex;
    flex-direction: column;

    &:hover {
      color: var(--primary-main5);
    }

    img {
      width: 3.25rem;
      height: 3.25rem;
      align-self: center;
      justify-self: center;
    }

    span {
      margin-top: 0.25rem;
      text-align: center;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      line-clamp: 1;
    }
  }
}
